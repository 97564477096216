import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import InfiniteScroll from "react-infinite-scroller";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import { apiPath } from "api";
import { generalAuthTokenHeader } from "__helpers/auth-header";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import CloseIcon from "@material-ui/icons/Close";
import { Scrollbars } from "react-custom-scrollbars";
import { findProviderService, downloadMedia } from "__helpers/util";

import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import {
  IconButton,
  Link as MaterialLink,
  LinearProgress,
  CircularProgress,
  TextField,
  Button,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from "@material-ui/core";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import DateFnsUtils from "@date-io/date-fns";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import notesArrow from "assets/img/icons/notes-arrow.svg";
import { connect } from "react-redux";
import { reduxLoad } from "js/actions/index";
import { userService } from "_services/user.service";
import {
  REFERRAL_TYPE,
  ALLOWED_PROFILE_IMAGE_SIZE,
  ALLOWED_FACESHEET_EXTENTION,
  MAX_INPUT_LENGTH_LONG,
} from "__helpers/constants";
import PatientCriteria from "views/dpViews/SinglePatientDetail/PatientCriteria";

import { FormErrors } from "../../components/Login/FormErrors";

import fileExtension from "file-extension";
import enMsg from "__helpers/locale/en/en";
import Comment from "components/Comments/Comment";
import PatientNotes from "views/dpViews/SinglePatientDetail/PatientNotes";
import TransportDetails from "./TransportDetails";

function mapDispatchToProps(dispatch) {
  return {
    reduxLoad: (reduxLoadVal) => dispatch(reduxLoad(reduxLoadVal)),
  };
}
const mapStateToProps = (state) => {
  return {
    userInfoRedux: state.userInfo,
    categoryListRedux: state.categoryList,
    insuranceListRedux: state.insuranceList,
    patientStatusListRedux: state.patientStatusList,
    hospitalListRedux: state.hospitalList,
    timestampRedux: state.timestamp,
    reduxLoadFlag: state.reduxLoadFlag,
  };
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const tooltipStyles = {
  tooltip: {
    width: "auto !important",
    // height: "36px",
    borderRadius: "18px",
    boxShadow: "0 20px 80px 0",
  },
};
const CustomTooltip = withStyles(tooltipStyles)(Tooltip);

class FinalizedProviderTabPanelClass extends React.Component {
  inputOpenFileRef = React.createRef(null);
  constructor(props) {
    super(props);
    this.dateUtility = new DateFnsUtils();
    let totalCategory = {
      1: {
        mainCategory: [],
        subCategory: [],
        childCategory: [],
      },
      2: {
        mainCategory: [],
        subCategory: [],
        childCategory: [],
      },
      3: {
        mainCategory: [],
        subCategory: [],
        childCategory: [],
      },
      4: {
        mainCategory: [],
        subCategory: [],
        childCategory: [],
      },
      5: {
        mainCategory: [],
        subCategory: [],
        childCategory: [],
      },
    };
    if (this.props.categoryListRedux) {
      if (this.props.categoryListRedux && this.props.categoryListRedux.length) {
        this.props.categoryListRedux.map((cList, key) => {
          // main loop
          if (cList.catagoryId == 1) {
            const { children } = cList;
            children.map((mChildren, mKey) => {
              if (mChildren.children && mChildren.children.length) {
                mChildren.children.map((cChildren, cKey) => {
                  totalCategory[1].childCategory.push(cChildren.catagoryId);
                });
              } else {
                totalCategory[1].subCategory.push(mChildren.catagoryId);
              }
            });
          }
          if (cList.catagoryId == 2) {
            const { children } = cList;
            children.map((mChildren, mKey) => {
              if (mChildren.children && mChildren.children.length) {
                mChildren.children.map((cChildren, cKey) => {
                  totalCategory[2].childCategory.push(cChildren.catagoryId);
                });
              } else {
                totalCategory[2].subCategory.push(mChildren.catagoryId);
              }
            });
          }
          if (cList.catagoryId == 3) {
            const { children } = cList;
            children.map((mChildren, mKey) => {
              if (mChildren.children && mChildren.children.length) {
                mChildren.children.map((cChildren, cKey) => {
                  totalCategory[3].childCategory.push(cChildren.catagoryId);
                });
              } else {
                totalCategory[3].subCategory.push(mChildren.catagoryId);
              }
            });
          }
          if (cList.catagoryId == 4) {
            const { children } = cList;
            children.map((mChildren, mKey) => {
              if (mChildren.children && mChildren.children.length) {
                mChildren.children.map((cChildren, cKey) => {
                  totalCategory[4].childCategory.push(cChildren.catagoryId);
                });
              } else {
                totalCategory[4].subCategory.push(mChildren.catagoryId);
              }
            });
          }
          if (cList.catagoryId == 5) {
            const { children } = cList;
            children.map((mChildren, mKey) => {
              if (mChildren.children && mChildren.children.length) {
                mChildren.children.map((cChildren, cKey) => {
                  totalCategory[5].childCategory.push(cChildren.catagoryId);
                });
              } else {
                totalCategory[5].subCategory.push(mChildren.catagoryId);
              }
            });
          }
        });
      }
    }
    let spinner = document.getElementById("loadingSpinner");
    this.state = {
      loading: false,
      isCatAvailable: [],
      refferalProviderEntity: [],
      refferalPatientEntity: [],
      providerData: {},
      page: 1,
      searchKeyword: "",
      providerIds: [],
      spinner: spinner,
      size: 10,
      refferalId: this.props.refferalId ? this.props.refferalId : "",
      patientId: JSON.parse(localStorage.getItem("_selected_patient_list")),
      fetchMore: false,
      categoryId: this.props.categoryId,
      selectedCategoryId: this.props.categoryId,
      totalPatients: 0,
      providerStatus: null,
      categoryList: props.categoryListRedux ? props.categoryListRedux : [],
      insuranceList: props.insuranceListRedux ? props.insuranceListRedux : [],
      totalCategoryIds: totalCategory,
      providerIdForStatusChange: "",
      providerReferralStatusId: "",
      patientInfo: this.props.patientInfo ? this.props.patientInfo : {},
      patientCriteriaModalStatus: false,
      comment: "",
      formErrors: {
        comment: "",
        file: "",
      },
      fileName: "",
      file: "",
      fileBlob: "",
      receiverId: "",
      fileValid: false,
      commentValid: false,
      openConfirmationBox: false,
      commentsThread: [],
      owlCarouselOptions: {
        loop: false,
        dots: false,
        margin: 0,
        nav: true,
        stagePadding: 1,
        items: 6,
        navText: [
          "<i class='fa fa-chevron-left'></i>",
          "<i class='fa fa-chevron-right'></i>",
        ],
        responsiveClass: true,
        responsive: {
          0: {
            items: 1,
          },
          576: {
            items: 3,
          },
          768: {
            items: 4,
          },
          850: {
            items: 2,
          },
          1100: {
            items: 3,
          },
          1200: {
            items: 3,
          },
          1400: {
            items: 4,
          },
          1600: {
            items: 5,
          },
          1800: {
            items: 6,
          },
        },
        patientNotesStatus: false,
      },
      referralPatientModal: false,
      refferalStatus: 3,
      transportDetailsModal: false,
    };
    this.searchProvider = this.searchProvider.bind(this);
    this.selectProviderCheckBox = this.selectProviderCheckBox.bind(this);
    this.selectProviderStatus = this.selectProviderStatus.bind(this);
    this.rescindReferral = this.rescindReferral.bind(this);
    this.patientCriteriaModalOpen = this.patientCriteriaModalOpen.bind(this);
    this.patientCriteriaModalClose = this.patientCriteriaModalClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.rescindReferralConfirmation = this.rescindReferralConfirmation.bind(
      this
    );
    this.handleConfirmBox = this.handleConfirmBox.bind(this);
    this.patientNotesOpen = this.patientNotesOpen.bind(this);
    this.viewReferralPatients = this.viewReferralPatients.bind(this);
  }
  async fetchProviders() {
    let url;
    url = apiPath.findAwaitingProvider + "?refferalId=" + this.state.refferalId;
    this.setState({
      loading: true,
    });
    this.props.loaderStatus(true);
    const response = await fetch(url, {
      method: "GET",
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        if (response.status === 400) {
        } else if (response.ok) {
          let totalPatientCount = response.headers.get("X-Total-Count");
          this.setState({
            totalPatients: totalPatientCount,
          });
        } else if (response.status === 401) {
        } else {
        }
        return response.json();
      })
      .then((data) => {
        if (data && data[0]) {
          data = data[0];
          if (data && data.refferalId) {
            if (this.state.refferalStatus != data.refferalStatus) {
              this.props.updatePatient();
              return false;
            }
            let refferalProviderEntity = [];
            let refferalPatientEntity = [];
            let receiverId = "";
            if (
              data.refferalProviderEntity &&
              data.refferalProviderEntity.length
            ) {
              refferalProviderEntity = data.refferalProviderEntity;
              refferalProviderEntity.map((listItem, index) => {
                if (listItem.providerStatusId === 3) {
                  receiverId = listItem.providerId;
                }
              });
            }
            if (
              data.refferalPatientEntity &&
              data.refferalPatientEntity.length
            ) {
              refferalPatientEntity = data.refferalPatientEntity;
            }
            this.setState(
              {
                refferalProviderEntity: refferalProviderEntity,
                refferalPatientEntity: refferalPatientEntity,
                providerData: data,
                receiverId: receiverId,
              },
              () => {
                this.fetchComments();
              }
            );
          }
        }
        this.setState({
          loading: false,
        });
        this.props.loaderStatus(false);
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        this.props.loaderStatus(false);
        return response;
      });
  }
  componentDidMount() {
    this.fetchProviders();
  }
  componentDidUpdate() {
    if (
      this.props.reduxLoadFlag != undefined &&
      this.state.reduxLoadFlag != this.props.reduxLoadFlag
    ) {
      let categoryList = [];
      let insuranceList = [];
      let hospitalList = [];
      let patientStatusList = [];
      let userInfo = {};
      let totalCategory = {
        1: {
          mainCategory: [],
          subCategory: [],
          childCategory: [],
        },
        2: {
          mainCategory: [],
          subCategory: [],
          childCategory: [],
        },
        3: {
          mainCategory: [],
          subCategory: [],
          childCategory: [],
        },
        4: {
          mainCategory: [],
          subCategory: [],
          childCategory: [],
        },
        5: {
          mainCategory: [],
          subCategory: [],
          childCategory: [],
        },
      };
      if (this.props.categoryListRedux) {
        let selectedcategoryList = this.props.categoryListRedux;
        categoryList = selectedcategoryList ? selectedcategoryList : [];

        if (categoryList && categoryList.length) {
          categoryList.map((cList, key) => {
            // main loop
            if (cList.catagoryId == 1) {
              const { children } = cList;
              children.map((mChildren, mKey) => {
                if (mChildren.children && mChildren.children.length) {
                  mChildren.children.map((cChildren, cKey) => {
                    totalCategory[1].childCategory.push(cChildren.catagoryId);
                  });
                } else {
                  totalCategory[1].subCategory.push(mChildren.catagoryId);
                }
              });
            }
            if (cList.catagoryId == 2) {
              const { children } = cList;
              children.map((mChildren, mKey) => {
                if (mChildren.children && mChildren.children.length) {
                  mChildren.children.map((cChildren, cKey) => {
                    totalCategory[2].childCategory.push(cChildren.catagoryId);
                  });
                } else {
                  totalCategory[2].subCategory.push(mChildren.catagoryId);
                }
              });
            }
            if (cList.catagoryId == 3) {
              const { children } = cList;
              children.map((mChildren, mKey) => {
                if (mChildren.children && mChildren.children.length) {
                  mChildren.children.map((cChildren, cKey) => {
                    totalCategory[3].childCategory.push(cChildren.catagoryId);
                  });
                } else {
                  totalCategory[3].subCategory.push(mChildren.catagoryId);
                }
              });
            }
            if (cList.catagoryId == 4) {
              const { children } = cList;
              children.map((mChildren, mKey) => {
                if (mChildren.children && mChildren.children.length) {
                  mChildren.children.map((cChildren, cKey) => {
                    totalCategory[4].childCategory.push(cChildren.catagoryId);
                  });
                } else {
                  totalCategory[4].subCategory.push(mChildren.catagoryId);
                }
              });
            }
            if (cList.catagoryId == 5) {
              const { children } = cList;
              children.map((mChildren, mKey) => {
                if (mChildren.children && mChildren.children.length) {
                  mChildren.children.map((cChildren, cKey) => {
                    totalCategory[5].childCategory.push(cChildren.catagoryId);
                  });
                } else {
                  totalCategory[5].subCategory.push(mChildren.catagoryId);
                }
              });
            }
          });
        }
      }
      if (this.props.insuranceListRedux) {
        let selectedinsuranceList = this.props.insuranceListRedux;
        insuranceList = selectedinsuranceList ? selectedinsuranceList : [];
      }
      if (this.props.hospitalListRedux) {
        let selectedhospitalList = this.props.hospitalListRedux;
        hospitalList = selectedhospitalList ? selectedhospitalList : [];
      }
      if (this.props.patientStatusListRedux) {
        let selectedpatientStatusList = this.props.patientStatusListRedux;
        patientStatusList = selectedpatientStatusList
          ? selectedpatientStatusList
          : [];
      }
      if (this.props.userInfoRedux) {
        let userInfoRedux = this.props.userInfoRedux;
        userInfo = userInfoRedux ? userInfoRedux : {};
      }
      this.setState({
        reduxLoadFlag: this.props.reduxLoadFlag,
        categoryList: categoryList,
        insuranceList: insuranceList,
        hospitalList: hospitalList,
        patientStatusList: patientStatusList,
        userInfo: userInfo,
        totalCategoryIds: totalCategory,
      });
    }
  }
  componentWillUnmount() {
    this.subscription = findProviderService.clearFindProvidersList();
  }
  fetchMoreData = () => {
    setTimeout(() => {
      if (this.state.fetchMore) {
        this.setState({
          page: this.state.page + 1,
        });
      }
    }, 2500);
  };
  searchProvider = (event) => {
    let allProviderList =
      this.state.providerData && this.state.providerData.refferalProviderEntity
        ? this.state.providerData.refferalProviderEntity
        : [];
    const searchText = event.target.value.trim();
    let allLocalProviderList = [];
    if (searchText) {
      allProviderList = allProviderList.filter((v, i) => {
        if (v.provider && v.provider.providerName) {
          return (
            v.provider.providerName
              .toLowerCase()
              .indexOf(searchText.toLowerCase().trim()) !== -1
          );
        }
      });
      this.setState({
        refferalProviderEntity: allProviderList,
      });
    }
    this.setState({
      refferalProviderEntity: allProviderList,
    });
  };
  getProviderId = (providerId) => {
    let providerIds = this.state.providerIds;
    let allItem = this.state.items;
    if (providerIds.includes(providerId)) {
      const index = providerIds.indexOf(providerId);
      if (index > -1) {
        providerIds.splice(index, 1);
        allItem.map((item) => {
          if (item.id == providerId) item["isChecked"] = false;
        });
      }
    } else {
      allItem.map((item) => {
        if (item.id == providerId) item["isChecked"] = true;
      });
      providerIds.push(providerId);
    }
    this.setState({
      providerIds: providerIds,
    });
  };
  providerStatusDropdown = (event, providerID, referralStatusId) => {
    this.setState({
      providerStatus: event.currentTarget,
      providerIdForStatusChange: providerID,
      providerReferralStatusId: referralStatusId,
    });
  };
  handleCloseForProviderStatus = () => {
    this.setState({
      providerStatus: null,
    });
  };
  selectProviderCheckBox(providerId) {
    this.props.selectedProviderDataCallback(providerId);
  }
  async rescindReferral() {
    let showNotification = "";
    let data = {};
    this.setState({
      loading: true,
      openConfirmationBox: false,
    });
    this.props.loaderStatus(true);
    let url =
      apiPath.rescindReferralDp + "?refferalId=" + this.state.refferalId;
    const response = await fetch(url, {
      method: "GET",
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Rescind Status",
            message: "Bad response from server",
            type: "danger",
          };
          this.props.loaderStatus(false);
        } else if (response.ok) {
          showNotification = {
            title: "Rescind Status",
            message: "Rescinded all providers successfull",
            type: "success",
          };
          this.props.updatePatient();
        } else {
          showNotification = {
            title: "Rescind Status",
            message: "Bad response from server.",
            type: "danger",
          };
          this.props.loaderStatus(false);
        }
        this.setState({
          loading: false,
        });
        return response.json();
      })
      .then((data) => {
        if (data.title) {
          showNotification = {
            title: "Rescind Status",
            message: data.title,
            type: "danger",
          };
        }
        userService.showNotification(showNotification);
        return true;
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        this.props.loaderStatus(false);
      });
  }
  rescindReferralConfirmation() {
    this.setState({
      openConfirmationBox: true,
    });
  }
  handleConfirmBox() {
    this.setState({
      openConfirmationBox: false,
      referralPatientModal: false,
    });
  }
  async selectProviderStatus(event) {
    if (this.state.providerReferralStatusId == event.target.value) {
      this.handleCloseForProviderStatus();
      return true;
    }
    this.state.spinner.removeAttribute("hidden", "true");
    let showNotification = "";
    let data = {
      providerId: this.state.providerIdForStatusChange,
      refferalId: this.state.refferalId,
      statusId: event.target.value,
    };
    const response = await fetch(apiPath.changeProviderStatus, {
      method: "PUT",
      headers: generalAuthTokenHeader(),
      body: JSON.stringify(data),
      data: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Provider Status",
            message: "Bad response from server",
            type: "danger",
          };
        } else if (response.ok) {
        } else {
          showNotification = {
            title: "Provider Status",
            message: "Bad response from server.",
            type: "danger",
          };
        }
        return response.json();
      })
      .then((data) => {
        this.fetchProviders();
        this.handleCloseForProviderStatus();
        showNotification = {
          title: "Provider Status",
          message: "Provider status change successfully.",
          type: "success",
        };
        return true;
      })
      .catch((error) => {
        showNotification = {
          title: "Provider Status",
          message: "Something went wrong.Please try after sometime..",
          type: "danger",
        };
      });
    this.state.spinner.setAttribute("hidden", "true");
    userService.showNotification(showNotification);
  }
  patientCriteriaModalOpen = () => {
    this.setState({
      patientCriteriaModalStatus: true,
    });
  };
  patientCriteriaModalClose = () => {
    this.setState({
      patientCriteriaModalStatus: false,
    });
  };
  searchReturn(event) {
    if (event.target.keyCode === 13 || event.key === "Enter") {
      event.preventDefault();
    }
  }
  setFilterProviderList = (filter) => {
    // do nothing
  };
  handleChangeInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateField(name, value);
      }
    );
  };
  validateField = (fieldName, fieldValue) => {
    let fieldValidationErrors = this.state.formErrors;
    let commentValid = this.state.commentValid;
    switch (fieldName) {
      case "comment":
        let commentErr = "";
        if (fieldValue.trim().length == 0) {
          commentValid = false;
          commentErr = "Comment is required";
        } else if (fieldValue.trim().length > MAX_INPUT_LENGTH_LONG) {
          commentValid = false;
          commentErr = "Comment length cannot exceed 255 characters";
        } else {
          commentValid = true;
          commentErr = "";
        }
        // commentValid = fieldValue.trim().length > 0;
        fieldValidationErrors.comment = commentErr;
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        commentValid: commentValid,
      },
      this.validateForm
    );
  };
  validateForm() {
    return this.state.commentValid || this.state.fileValid;
  }
  fetchComments = () => {};
  onFileBrowse = (event) => {
    this.inputOpenFileRef.current.click();
  };
  async handleSubmit(event) {
    event.preventDefault();
    let data = {
      base64Data: this.state.file,
      description: this.state.comment,
      fileName: this.state.fileName,
      receiverId: this.state.providerData.refferalProviderEntity[0]
        ? this.state.providerData.refferalProviderEntity[0].providerId
        : "",
      refferalId: this.state.providerData.refferalProviderEntity[0]
        ? this.state.providerData.refferalProviderEntity[0].refferalId
        : "",
    };
    this.setState({ loading: true });
    let showNotification = {};
    let modalCloseStatus = 1;
    const response = await fetch(apiPath.postCommentByDP, {
      method: "POST",
      headers: generalAuthTokenHeader(),
      body: JSON.stringify(data),
      data: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Comment",
            message: enMsg.badResponseFromServer,
            type: "danger",
          };
        } else if (response.ok) {
        } else {
          showNotification = {
            title: "Comment",
            message: enMsg.badResponseFromServer,
            type: "danger",
          };
        }
        return response.json();
      })
      .then((data) => {
        if (data.id) {
          this.fetchComments();
          this.setState({
            comment: "",
            fileName: "",
            file: "",
            commentValid: false,
            fileValid: false,
          });
          showNotification = {
            title: "Comment",
            message: "Message has been sent to the provider",
            type: "success",
          };
        }
      })
      .catch((error) => {
        showNotification = {
          title: "Comment",
          message: enMsg.clientSideError,
          type: "danger",
        };
      });
    userService.showNotification(showNotification);
    this.setState({ loading: false });
  }
  handleSelectedFile = (event) => {
    event.preventDefault();
    let file = event.target.files[0];
    if (typeof file === "undefined" && this.state.fileName == "") {
      this.setState({
        commentValid: false,
        fileValid: false,
      });
      return;
    }
    let fieldValidationErrors = this.state.formErrors;
    let error = "";
    let fileValid = false;
    if (typeof file == "object" && file.size > ALLOWED_PROFILE_IMAGE_SIZE) {
      error = enMsg.allowedFaceSheetSize;
    } else if (
      typeof file == "object" &&
      !ALLOWED_FACESHEET_EXTENTION.includes(file.type)
    ) {
      error = enMsg.allowedFaceSheetType;
    } else {
      fileValid = true;
    }

    if (typeof file !== undefined && fileValid) {
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        let result = fileReader.result;
        this.setState({
          fileName: file.name,
          file: result,
        });
        this.fileBlob = file;
        this.filename = new Date().getTime() + "." + fileExtension(file.name);
      });
      if (file) {
        fileReader.readAsDataURL(file);
      }
    }
    if (!fileValid) {
      this.setState({
        fileName: "",
      });
    }

    fieldValidationErrors.file = fileValid ? "" : error;
    this.setState(
      {
        fileValid: fileValid,
        formErrors: fieldValidationErrors,
      },
      this.validateForm
    );
  };

  downloadFileFax = async (fileName,fileUrl) => {
    let showNotification = {};
    try {
        let showNotification = {
            title: 'Fax Details',
            message: 'Please wait, Downloading Fax files',
            type: "info"
        };
        userService.showNotification(showNotification);
        let urlArr = fileUrl.split('/')
        let urlArrEndPoint = urlArr[urlArr.length - 2] + "/" + urlArr[urlArr.length - 1];
        let dataUses = "";
        dataUses = "?key=" + urlArrEndPoint;
        let sheetData = await userService.fetchCommentSheetsData(apiPath.downloadCommentFile + dataUses, fileName);
    } catch (error) {
        showNotification = {
            title: 'Fax',
            message: 'No Fax available.',
            type: "danger"
        };
        userService.showNotification(showNotification);
    }
    this.setState({
        loading: false,
    })
    return false;
}


  downloadFile = async (fileName, fileUrl) => {
    this.setState({
      loading: true,
    });
    let showNotification = {};
    try {
      downloadMedia(fileUrl, fileName);
    } catch (error) {
      showNotification = {
        title: "Facesheet",
        message: "No facesheet available.",
        type: "danger",
      };
      userService.showNotification(showNotification);
    }
    this.setState({
      loading: false,
    });
    return false;
  };
  patientNotesOpen() {
    let notesStatus = this.state.patientNotesStatus ? false : true;
    this.setState({
      patientNotesStatus: notesStatus,
    });
  }
  viewReferralPatients() {
    this.setState({
      referralPatientModal: true,
    });
  }
  toggleTransportDetailsModal = () => {
    const transportDetailsModal = this.state.transportDetailsModal;

    this.setState({
      transportDetailsModal: !transportDetailsModal,
    });
  };
  render() {
    const {
      refferalProviderEntity,
      refferalPatientEntity,
      providerData,
      loading,
      provider,
      isCatAvailable,
      selectedCategoryId,
      totalCategoryIds,
    } = this.state;
    let availableCatUser = [];
    return (
      <>
        {this.state.categoryId && (
          <PatientCriteria
            modalStatus={this.state.patientCriteriaModalStatus}
            modalClose={this.patientCriteriaModalClose}
            selectCategoryId={this.state.categoryId}
            patientId={this.state.patientId}
            patientInfo={this.state.patientInfo}
            setFilterProviderList={this.setFilterProviderList}
            readOnly={true}
          />
        )}
        <PatientNotes
          modalStatus={this.state.patientNotesStatus}
          // modalClose={this.patientNotesClose()}
          selectCategoryId={this.state.categoryId}
          patientInfo={this.state.patientInfo}
        />
        <Dialog
          open={this.state.openConfirmationBox}
          maxWidth={"xs"}
          onClose={this.handleConfirmBox}
          TransitionComponent={Transition}
          aria-labelledby="form-dialog-title"
          className="add-modal front-modal "
        >
          <DialogTitle className="add-modal-title" id="form-dialog-title">
            Rescind Referral
            <IconButton
              className="closeButton"
              aria-label="close"
              onClick={this.handleCloseForProviderStatus}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box
              width={1}
              display="flex"
              justifyContent="flex-start"
              mb={1}
              mt={1}
            >
              <Box pr={2}>Do you want rescind this referral ?</Box>
            </Box>
          </DialogContent>
          <DialogActions className="modal-actions" justify="center">
            <Button
              type="button"
              className="btn1"
              onClick={this.rescindReferral}
            >
              Confirm
            </Button>
            <Button onClick={this.handleConfirmBox} className="cancel-link">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.referralPatientModal}
          maxWidth={"sm"}
          onClose={this.handleConfirmBox}
          TransitionComponent={Transition}
          aria-labelledby="form-dialog-title"
          className="add-modal front-modal ViewInvitemodal"
        >
          <DialogTitle className="add-modal-title " id="form-dialog-title">
            Referral Patients
            <IconButton
              className="closeButton"
              aria-label="close"
              onClick={this.handleConfirmBox}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box
              display="flex"
              flexDirection="column"
              width={1}
              className="patient-list-page AwaitingProviderTabPanelCSS patient-list-page-new patient-info-page-new  FinalisedTab ViewInvite"
            >
              <Box display="flex" pr={2} className="divcontainer-tab " mb={2}>
                <Box pr={1} className="title-box">
                  <h4 className="label">Referral ID</h4>
                  <h4 className="value">{this.state.refferalId}</h4>
                </Box>
                <Box pr={1} className="title-box">
                  <h4 className="label">Total Patients</h4>
                  <h4 className="value">
                    {refferalPatientEntity && refferalPatientEntity.length
                      ? refferalPatientEntity.length
                      : 0}
                  </h4>
                </Box>
                <Box pr={1} className="title-box">
                  <h4 className="label">Referral Sent</h4>
                  <h4 className="value">{providerData.refferalSendTime}</h4>
                </Box>
              </Box>
              <Box
                width={1}
                className="left-content-list left-content-list-New"
              >
                <div className="PatientList view-ref-cover">
                  {/* <Scrollbars style={{ height: "600px" }} universal={true} className="YOnlytScrollbars TabScroll"> */}
                  <Box
                    universal={true}
                    className="YOnlytScrollbars TabScroll min-height-threehundred"
                  >
                    {refferalPatientEntity && refferalPatientEntity.length
                      ? refferalPatientEntity.map((listItem, index) => {
                          const {
                            patientId,
                            patientEntity,
                            providerSearchHistoryEntity,
                          } = listItem;
                          let categoryId = [];
                          const urlParams = new URLSearchParams(
                            providerSearchHistoryEntity
                              ? providerSearchHistoryEntity.search
                              : "subCategoryId"
                          );
                          let subCategoryId = urlParams.get("subCategoryId");
                          subCategoryId = subCategoryId.split(",");
                          if (subCategoryId && subCategoryId[1]) {
                            categoryId = subCategoryId[1]
                              .split("-")
                              .map((item) => Number(item));
                          }
                          return (
                            <Box
                              width={1}
                              display="flex"
                              flexDirection="row"
                              mb={2}
                              pr={2}
                              key={index}
                              className="AllReferrals-page"
                            >
                              <div className="list-item-content w-100 p-right">
                                <Box
                                  className="list-item-content-data"
                                  width={1}
                                  pt={2}
                                  display="flex"
                                  flexDirection="row"
                                >
                                  <Box width={1 / 2} pr={1} className="pointer">
                                    <div className="user-info-tab-finalised">
                                      <div className="name">
                                        {patientEntity.lastName +
                                          ", " +
                                          patientEntity.firstName}
                                      </div>
                                      <div className="date-insurance">
                                        Est Discharge{" "}
                                        {
                                          patientEntity.formattedEstimatedDischargeDate
                                        }
                                      </div>
                                    </div>
                                    <div className="user-info-tab-finalised">
                                      Insurance:{" "}
                                      {this.state.insuranceList.map(
                                        (insurance) => {
                                          if (
                                            insurance.insuranceId ==
                                            patientEntity.insuranceId
                                          ) {
                                            return insurance.insuranceName;
                                          }
                                        }
                                      )}
                                    </div>
                                  </Box>
                                  <Box
                                    pl={1}
                                    width={1 / 2}
                                    className="Ref-category"
                                  >
                                    <Box
                                      width={1}
                                      display="flex"
                                      flexWrap="wrap"
                                      className=""
                                    >
                                      {this.state.categoryList &&
                                      this.state.categoryList.length &&
                                      categoryId &&
                                      categoryId.length
                                        ? this.state.categoryList.map(
                                            (cList, key) => {
                                              if (
                                                categoryId.includes(cList.id)
                                              ) {
                                                return (
                                                  <Box
                                                    width={1 / 2}
                                                    mr={1}
                                                    mb={1}
                                                    className="Ref-categorybox"
                                                    key={key}
                                                  >
                                                    {cList.categoryValue}
                                                  </Box>
                                                );
                                              }
                                            }
                                          )
                                        : null}
                                    </Box>
                                  </Box>
                                </Box>
                              </div>
                            </Box>
                          );
                        })
                      : "No Patient Found"}
                  </Box>
                </div>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
        <TransportDetails
          open={this.state.transportDetailsModal}
          toggle={this.toggleTransportDetailsModal}
          transportDetails={this.state.providerData.refferalTransportEntity}
        />
        <Box
          display="flex"
          flexDirection="row"
          width={1}
          className="patient-list-page AwaitingProviderTabPanelCSS patient-list-page-new patient-info-page-new withoutCheckboXContent FinalisedTab"
        >
          {providerData && providerData.refferalId ? (
            <Box pr={1} pl={0} className="left-content-box">
              <div className="left-content-header">
                <Box display="flex" pr={2} className="divcontainer-tab " mb={2}>
                  <Box pr={1} className="title-box">
                    <h4 className="label">Referral ID</h4>
                    <h4 className="value">{this.state.refferalId}</h4>
                  </Box>
                  <Box pr={1} className="title-box">
                    <h4 className="label">Referral Type</h4>
                    {providerData.faxStatus != null &&  providerData.faxStatus != "" ? 
                    <h4 className="value">
                      FAX
                    </h4>
                    :
                    <h4 className="value">
                     {providerData.refferalType == "3" ? "MANUAL" : REFERRAL_TYPE[providerData.refferalType] }
                    </h4>
                  }

                  
                  </Box>
                  <Box pr={1} className="title-box">
                    <h4 className="label">Last Referral Sent</h4>
                    <h4 className="value">{providerData.refferalSendTime}</h4>
                  </Box>
                  <Box
                    className="action-box-tab"
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                  >
                    <Box pl={1} pr={1} style={{ display: "none" }}>
                      <Tooltip title="Search By Name" placement="top" arrow>
                        <Paper component="form" className="search-box-cover">
                          <InputBase
                            className="search-box"
                            placeholder="Enter search here..."
                            onChange={this.searchProvider}
                            onKeyPress={this.searchReturn}
                          />
                          <IconButton
                            type="submit"
                            className=""
                            aria-label="search"
                          >
                            <SearchIcon />
                          </IconButton>
                        </Paper>
                      </Tooltip>
                    </Box>
                    {this.state.providerData.refferalTransportEntity && (
                      <Box pl={1} className="action-filter">
                        <button
                          className="pointer btn4"
                          onClick={() => this.toggleTransportDetailsModal()}
                        >
                          Transport Details
                        </button>
                      </Box>
                    )}
                     {this.state.providerData.faxUploadHistory && (
                      <Box pl={1} className="action-filter">
                        <button  className="pointer btn4" aria-hidden="true"  onClick={() => {
                                                providerData.faxUploadHistory.forEach((faxHistory) => {
                                                  if (faxHistory.providerId === providerData.refferalProviderEntity[0].providerId) {
                                                    // console.log(faxHistory.providerId); 
                                                    // console.log("overall", provider.organizationEntity);  
                                                    // console.log("detailsfax", faxHistory.faxFileName, faxHistory.faxFileUrl);                                                     
                                                   
                                                     this.downloadFileFax(faxHistory.faxFileName, faxHistory.faxFileUrl);   
                                                  }
                                                });}}>
                          Fax Details
                        </button>
                      </Box>
                    )}
                    <Box pl={1} className="action-filter">
                      <button
                        className="pointer btn4"
                        onClick={() => this.viewReferralPatients()}
                      >
                        View Referral Patients
                      </button>
                    </Box>

                    <Box
                      pl={1}
                      className="action-filter"
                      style={{ display: "" }}
                    >
                      <button
                        className="pointer btn4"
                        onClick={() => this.rescindReferralConfirmation()}
                      >
                        Rescind Referral
                      </button>
                    </Box>
                  </Box>
                </Box>
              </div>
              <Box
                width={1}
                className="left-content-list left-content-list-New"
              >
                <div className="PatientList">
                  <Scrollbars
                    style={{ height: "calc(100vh - 518px)" }}
                    universal={true}
                    className="YOnlytScrollbars TabScroll"
                  >
                    {refferalProviderEntity && refferalProviderEntity.length ? (
                      refferalProviderEntity.map((listItem, index) => {
                        const {
                          provider,
                          masterRefferalStatusEntity,
                        } = listItem;
                        if (listItem.providerStatusId === 3) {
                          return (
                            <Box
                              width={1}
                              display="flex"
                              flexDirection="row"
                              mb={2}
                              pr={2}
                              key={index}
                            >
                              <div className="list-item-content w-100 p-right">
                                <Box
                                  className="list-item-content-data"
                                  width={1}
                                  display="flex"
                                  flexDirection="row"
                                >
                                  <Box
                                    pr={1}
                                    className="pointer"
                                    onClick={() =>
                                      this.selectProviderCheckBox(
                                        listItem.providerId
                                      )
                                    }
                                  >
                                    <div className="user-info-tab-finalised">
                                      <div
                                        className=""
                                        title={
                                          provider.organizationEntity
                                            ? provider.organizationEntity
                                                .orgName
                                            : provider.providerName
                                        }
                                      >
                                        {provider.organizationEntity
                                          ? provider.organizationEntity.orgName
                                          : provider.providerName}...
                                       <span className="infoicon-svg">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="18"
                                              height="18"
                                              viewBox="0 0 15 15"
                                            >
                                              <path
                                                id="information_outline"
                                                data-name="information outline"
                                                d="M7.4,6.2H8.6V5H7.4M8,12.8A4.8,4.8,0,1,1,12.8,8,4.806,4.806,0,0,1,8,12.8ZM8,2a6,6,0,1,0,6,6A6,6,0,0,0,8,2Zm-.6,9H8.6V7.4H7.4Z"
                                                transform="translate(-1.999 -1.998)"
                                                fill="#0D81B4"
                                              />
                                            </svg>
                                          </span>
                                      </div>
                                      <div className="date-insurance">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="14.609"
                                          height="14.608"
                                          viewBox="0 0 14.609 14.608"
                                        >
                                          <path
                                            id="phone"
                                            d="M5.94,9.324a12.228,12.228,0,0,0,5.347,5.344l1.785-1.788a.813.813,0,0,1,.825-.2,9.326,9.326,0,0,0,2.9.461.811.811,0,0,1,.812.812v2.84a.811.811,0,0,1-.812.812A13.8,13.8,0,0,1,3,3.81.811.811,0,0,1,3.81,3h2.84a.811.811,0,0,1,.812.812,9.326,9.326,0,0,0,.461,2.9.811.811,0,0,1-.2.824Z"
                                            transform="translate(-2.999 -2.998)"
                                            fill="#01a7a6"
                                          />
                                        </svg>
                                        <span className="phoneNumber">
                                          {provider.user.phoneNumber
                                            ? "(" +
                                              provider.user.phoneNumber.match(
                                                /(\d{3})(\d{3})(\d{4})/
                                              )[1] +
                                              ") " +
                                              provider.user.phoneNumber.match(
                                                /(\d{3})(\d{3})(\d{4})/
                                              )[2] +
                                              "-" +
                                              provider.user.phoneNumber.match(
                                                /(\d{3})(\d{3})(\d{4})/
                                              )[3]
                                            : "N/A"}
                                        </span>
                                      </div>
                                      <div className="date-insurance mt-5">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="14.609"
                                          height="14"
                                          viewBox="0 0 13.312 15.214"
                                        >
                                          <path
                                            id="user-minus"
                                            d="M6.656,7.607a3.8,3.8,0,1,0-3.8-3.8A3.8,3.8,0,0,0,6.656,7.607Zm2.662.951h-.5a5.172,5.172,0,0,1-4.332,0h-.5A4,4,0,0,0,0,12.551v1.236a1.427,1.427,0,0,0,1.426,1.426H11.886a1.427,1.427,0,0,0,1.426-1.426V12.551A4,4,0,0,0,9.318,8.558Z"
                                            fill="#01a7a6"
                                          />
                                        </svg>
                                        <span className="phoneNumber v-top ">
                                          {provider.user.firstName}{" "}
                                          {provider.user.lastName}
                                        </span>
                                      </div>
                                    </div>
                                  </Box>
                                  <Box
                                    className="OwlCarousel-box-finalised"
                                    pr={2}
                                    my={"auto"}
                                  >
                                    {provider?.array &&
                                    provider?.array.length ? (
                                      <OwlCarousel
                                        className="owl-theme owl-tab-theme"
                                        key={`carousel_${provider.array.length}`}
                                        {...this.state.owlCarouselOptions}
                                      >
                                        {provider?.array
                                          .sort((a, b) =>
                                            a.masterCategories?.categoryValue >
                                            b.masterCategories?.categoryValue
                                              ? 1
                                              : -1
                                          )
                                          .map((el, index) => {
                                            const {
                                              parent,
                                              masterCategories,
                                              value,
                                              children,
                                              categoryId,
                                            } = el;
                                            if (
                                              (totalCategoryIds[
                                                (providerData?.categoryId)
                                              ]?.subCategory?.includes(
                                                categoryId
                                              ) ||
                                                totalCategoryIds[
                                                  (providerData?.categoryId)
                                                ]?.childCategory?.includes(
                                                  categoryId
                                                )) &&
                                              value != ""
                                            ) {
                                              if (
                                                !availableCatUser.includes(
                                                  provider.id
                                                )
                                              ) {
                                                availableCatUser.push(
                                                  provider.id
                                                );
                                              }
                                              return (
                                                <Box
                                                  className="item menu-item"
                                                  key={index}
                                                  border={1}
                                                >
                                                  <p className="category-type">
                                                    {
                                                      masterCategories.categoryValue
                                                    }
                                                  </p>
                                                  {value && value != "" ? (
                                                    <p className="amount-value">
                                                      {value && value != ""
                                                        ? "$" + value
                                                        : "$0"}
                                                    </p>
                                                  ) : null}
                                                </Box>
                                              );
                                            }
                                          })}
                                      </OwlCarousel>
                                    ) : null}
                                  </Box>
                                  <Box  pr={2} display="flex" flexDirection="row">
                                      {providerData.faxStatus != null &&  providerData.faxStatus != "" && !provider.registeredStatus? 

                                        <div className="tag-referral">
                                             <div>
                                              Fax:{providerData.faxStatus}
                                            </div>
                                        </div>
                                          :""}
                                  </Box>
                                  <Box className="tag-finalised">
                                    <div className="tag-referral">
                                      Referral Finalized:
                                      {" " +
                                        this.dateUtility.format(
                                          new Date(
                                            listItem.formattedUpdatedDate
                                          ),
                                          "MM/dd/yyyy HH:mm:ss"
                                        )}
                                    </div>
                                  </Box>
                                </Box>
                              </div>
                            </Box>
                          );
                        }
                      })
                    ) : (
                      <div className="no-found-text">
                        Acccepted Provider Not Found
                      </div>
                    )}
                  {refferalProviderEntity && refferalProviderEntity.length ? (
  refferalProviderEntity.map((listItem, index) => {
    const { provider } = listItem;

    if (listItem.providerStatusId === 3 && provider.registeredStatus) {
      return (
        <div key={index} className="comments-section match-height-table">
          <div className="heading">Comments</div>

          {this.state.providerData.refferalProviderComment &&
          this.state.providerData.refferalProviderComment.length ? null : null}

          {this.state.providerData.refferalProviderEntity?.[0] &&
          this.state.providerData.refferalPatientEntity?.[0] ? (
            <Comment
              referralId={
                this.state.providerData.refferalProviderEntity[0].refferalId
              }
              receiverId={this.state.receiverId}
              patientId={this.state.patientId}
              prelimComment={
                this.state.providerData?.refferalProviderEntity?.[0]
                  ?.refferalProviderComment?.length
                  ? this.state.providerData.refferalProviderEntity[0]
                      .refferalProviderComment
                  : []
              }
            />
          ) : null}
        </div>
      );
    }
    return null; // Ensures map() doesn't break
  })
) : null}

                  </Scrollbars>
                </div>
              </Box>
            </Box>
          ) : loading ? (
            <Box pr={3} pl={2} width={1} display="flex" justifyContent="center">
              <CircularProgress size={24} className="CircularProgress-CSS " />
            </Box>
          ) : (
            <div className="no-found-text">Providers Not Available</div>
          )}
        </Box>
      </>
    );
  }
}

export const FinalizedProviderTabPanel = connect(
  mapStateToProps,
  mapDispatchToProps
)(FinalizedProviderTabPanelClass);
export default FinalizedProviderTabPanel;
